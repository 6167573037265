/* colors */
/* colors trans*/
/* $whiteGrey: */
/* font sizes and widths */
html {
  overflow-x: hidden;
}

:root {
  /* colors */
  --red: #F23645;
  --green: #089981;
  --blue: #2F67F0;
  --white: #FFFFFF;
  --black: #000000;
  --black-grey: rgba(0, 0, 0, 0.5);
  --light: #F9FAFA;
  --yellow: #FFF0A5;
  --orange: #FF8D03;
  --pink: #FEEAEA;
  /*light colors*/
  --red-light: rgba(242, 54, 69, 0.1);
  --green-light: rgba(8, 153, 129, 0.1);
  --blue-light: rgba(47, 103, 240, 0.1);
  --white-light: rgba(255, 255, 255, 0.1);
  --black-light: rgba(0, 0, 0, 0.1);
  --black-grey-light: rgba(0, 0, 0, 0.1);
  --light-light: rgba(249, 250, 250, 0.1);
  --yellow-light: rgba(255, 141, 3, 0.1);
  --linerar-gradients: linear-gradient(179.72deg, #2F67F0 8.93%, #002682 88.37%);

  /* --white-grey: */
  /* font sizes and widths */
  --font-weight-xl: 600;
  --font-size-l: 24px;

  --font-weight-l: 600;
  --font-size-ml: 18px;
  --font-weight-ml: 500;
  --font-size-m: 14px;
  --font-weight-m: 500;
  --font-size-s: 12px;
  --font-weight-s: 400;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

.lable-bg-blue {
  background-color: #2F67F0;
  color: #fff;
}

/* alignments components */
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Standard syntax */
  /* for scollbar */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

*::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  font-family: "Lato", sans-serif !important;
}

/* start common css */
.bg-light {
  background-color: var(--light);
}

.bg-white {
  background-color: var(--white);
}

.bg-blue {
  background-color: var(--blue);
}

.bg-red {
  background-color: var(--red);
}

.bg-green {
  background-color: var(--green);
}

.bg-yellow {
  background-color: var(--yellow);
}

.bg-dark-grey {
  background-color: var(--black-grey);
}

.bg-light-light {
  background-color: var(--light-light);
}

.bg-white-light {
  background-color: var(--white-light);
}

.bg-blue-light {
  background-color: var(--blue-light);
}

.bg-red-light {
  background-color: var(--red-light);
}

.bg-green-light {
  background-color: var(--green-light);
}

.bg-yellow-light {
  background-color: var(--yellow-light);
}

.bg-dark-grey-light {
  background-color: var(--black-grey-light);
}

.bg-gradients {
  background: var(--linerar-gradients);
}

.font-red {
  color: var(--red);
}

.font-green {
  color: var(--green);
}

.font-blue {
  color: var(--blue);
}

.font-black {
  color: var(--black);
}

.font-white {
  color: var(--white);
}

.font-dark-grey {
  color: var(--black-grey);
}

.font-pink {
  color: var(--pink);
}

.bg-pink {
  background-color: var(--pink);
}

.font-m {
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-s);
}

.font-s {
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-s);
}

.font-l {
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-l);
}

/* end common css */

.app {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--black);
  padding: 0px;
  margin: 0px;
  /* margin-bottom: 20px; */
  max-height: 100vh;
}

.screen {
  position: relative;
  /* border-radius: 0 0 20px 20px; */
  /* border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px; */
}

.main-content {
  min-width: 375px;
  width: 100%;
  max-width: 375px;
  padding: 16px 16px 80px 16px;

  height: 100vh;
  /* min-height: 737px; */
  border-radius: 20px;
  /* margin-bottom: 30px; */
  overflow-y: auto;
}


/* start splash screen */
.splash-content {
  height: 777px;
  min-width: 375px;
  width: 100%;
  border-radius: 20px;
  max-width: 375px;
}

.splash-wrapper {
  position: relative;
  height: 100%;
  border-radius: 20px;
  z-index: 1;
  background-color: #4579FF26;
  display: flex;
  align-items: center;
  justify-content: center;
}

.splash-wrapper:after {
  background-image: url(/public/images/shad-bg.png);
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  bottom: 0;
  right: 0;
  z-index: -1;
}

/* end splash screen */

/* start onboarding screen */
.small-title {
  font-size: 20px;
  color: #000;
  line-height: 24px;
  font-weight: 700;
}

.big-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  color: #000;
  margin-bottom: 10px;
}

.onboarding-text {
  width: 86%;
  margin: 0 auto 26px;
  text-align: center;
}

.text {
  font-size: 16px;
  line-height: 22px;
  color: #00000080;
  font-weight: 400;

}

button.signin-btn.btn.btn-primary {
  background-color: #2F67F0;
  border-radius: 8px;
  border: 0;
  border: 1px solid #2F67F0;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
  padding: 14px 55px;
}

button.signup-btn.btn.btn-primary {
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid #2F67F0;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #2F67F0;
  padding: 14px 55px;
}

/* end onboarding screen */

.btn.btn-primary.closeall-btn {
  display: inline-block;
  padding: 3px 8px;
  border-radius: 3px;
  font-weight: 400;
  font-size: 12px;
  border: 0;
  text-transform: uppercase;
  color: #f23645;
  background-color: rgba(242, 54, 69, 0.1);
}

.btn.btn-primary.closeall-btn:hover {
  color: #f23645;
  background-color: rgba(242, 54, 69, 0.1);
}

/* .btn {
  text-align: center;
  text-decoration: none;
  border: none;
  display: inline-block;
  outline: none;
}
.btn:hover, .btn-md:hover {
  cursor: pointer;
}
.btn-md {
  padding: 10px 20px;
}
.btn-sm {
  padding: 8px 15px;
}
.btn-cy {
  border-radius: 50px;
  border: none;
}
.btn-block {
  display: block;
} */

.card {
  padding: 10px;
  border-radius: 5px;
}

.icon-red {
  color: var(--red);
  background-color: rgba(242, 54, 69, 0.1);
  width: 35px;
  height: 35px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.icons-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 0;
}

.notification-count {
  width: 6px;
  height: 6px;
  color: #fff;
  border-radius: 50%;
  background-color: #F02D2D;
  padding: 10px;
  line-height: 6px;
  text-align: center;
  position: relative;
  top: -10px;
  left: -10px;
  display: inline-flex;
  font-size: 10px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
}

.custom-tab.nav-tabs {
  border-bottom: 0;
}

.custom-tab.nav-tabs .nav-link {
  padding: 0;
  border: 0;
  margin-bottom: 0;
  font-weight: 600;
  color: #000;
}

.custom-tab.nav-tabs .nav-link.active {
  background-color: inherit;
  border-bottom: 2px solid #2F67F0;
}

.topgainer-btn.btn-primary,
.toplosser-btn.btn-primary {
  background-color: #F9FAFA;
  border-radius: 50px;
  border: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #000;
  padding: 9px 18px;
}

.button-tabs.nav-tabs {
  padding: 16px;
  border: 0;
  margin-bottom: 13px;
  background-color: #fff;
}

.button-tabs.nav-tabs .nav-link {
  background-color: #F9FAFA;
  color: #000;
  border-radius: 50px;
  border: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  padding: 9px 18px;
}

.button-tabs.nav-tabs .nav-link.active {
  background-color: #2F67F0;
  border-radius: 50px;
  border: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #fff;
  padding: 9px 18px;
}

/* start footer */
.footer {
  min-width: 375px;
  width: 100%;
  max-width: 375px;
  box-shadow: 0px -1px 14px 0px #0000001A;
  height: inherit;
  position: absolute;
  bottom: 0;
}

.footer-tabs.nav-tabs {
  background-color: #fff;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.footer-tabs.nav-tabs .nav-link {
  padding: 12px 16px;
  font-size: 12px;
  border: 0;
  text-align: center;
  color: #00000080;
  border-bottom-left-radius: 20px;
}

.footer-tabs.nav-tabs .nav-link.active {
  color: #2F67F0;
  border-bottom-right-radius: 20px;
}

.footer-tabs.nav-tabs .nav-link.active>div img {
  filter: brightness(0) saturate(100%) invert(34%) sepia(63%) saturate(4468%) hue-rotate(216deg) brightness(97%) contrast(93%);
  opacity: 1;
}

/* end footer */





/* login */
.form-control.custom-form-control.eye-icons {
  padding-right: 2.2rem;
}

.form-control.custom-form-control {
  /* background-color: #F9FAFA; */
  background-color: #FFF;
  border-radius: 5px;
  border: 0;

}

.form-control.custom-form-control.grey-bg {
  background-color: #F9FAFA;
}

.form-control.custom-form-control::placeholder {
  color: #00000080;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}

.form-label.custom-label {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #1A1A1A;
  margin-bottom: 8px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.input-icon {
  position: relative;
}

.input-icon .right-icon {
  position: absolute;
  bottom: 16px;
  right: 9px;
}

.custom-link {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: #2F67F0;
  text-decoration: none;
}

.normal-link {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: #494949;
  text-decoration: none;
}

button.signin-btn.btn.btn-primary {
  background-color: #2F67F0;
  border-radius: 8px;
  border: 0;
  border: 1px solid #2F67F0;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
  padding: 14px 55px;
}


/* verification otp */
.small-p,
.span {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
}

.medium-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  color: #000;
  margin-bottom: 10px;
}

.verification-main a {
  text-decoration: none;
}

.verification-main p {
  color: #000000;
  opacity: 50%;
}

.verification-main .verification-code .code-box {
  background-color: #F9FAFA;
  height: 58px;
  width: 58px;
  border-radius: 10px;
}

.verification-main .time-count {
  color: #000;
  opacity: 50%;
}

.verification-main button {
  width: 330px;
}

.signup-wrapper {
  min-width: 375px;
  width: 100%;
  max-width: 375px;
  padding: 16px;
  border-radius: 20px;
  height: 900px;
}

/* start watchlist */
.form-control.search-form-control {
  background-color: #fff;
  border-radius: 50px;
  border: 0;
  padding: .375rem 1rem;
  padding-right: 2.2rem;
  font-size: 12px;
  line-height: 18px;
}

.form-control.search-form-control::placeholder {
  color: #00000080;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

.search-icon {
  position: relative;
}

.search-icon .right-icon {
  position: absolute;
  bottom: 13px;
  right: 13px;

}

.watchlist-tab.nav-tabs {
  border-bottom: 0;
  margin-bottom: 16px;
}

.watchlist-tab.nav-tabs .nav-link {
  padding: 8px 14px;
  border: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.watchlist-tab.nav-tabs .nav-link.active {
  background-color: #2F67F0;
  border-radius: 50px;
  border: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #fff;
  padding: 8px 14px;
}

.bg-green.alert-primary {
  background-color: var(--green);
  border: 0;
  padding: 15px 21px;
  /* padding-right: 30px; */
}

.bg-red.alert-primary {
  background-color: var(--red);
  border: 0;
  padding: 15px 21px;
  /* padding-right: 30px; */
}

.bg-red.alert-primary .alert-heading,
.bg-green.alert-primary .alert-heading {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 0;
}

.bg-red.alert-primary.alert-dismissible .btn-close,
.bg-green.alert-primary.alert-dismissible .btn-close {
  padding: 0;
  top: 16px;
  background-image: url(/public/images/alert-close.svg);
  color: #fff;
  opacity: 1;
  right: 10px
}

.bg-red.alert-primary.alert-dismissible .btn-close:focus,
.bg-green.alert-primary.alert-dismissible .btn-close:focus {
  box-shadow: unset;
}

.toast-message {
  position: relative;
  z-index: 9;
  display: flex;
  justify-content: center;
}

.bg-green.alert-primary {
  position: absolute;
  /* width: 100%; */
  bottom: 80px;
  margin-bottom: 0;
  border-radius: 50px;
}

.bg-red.alert-primary {
  position: absolute;
  width: 100%;
  bottom: 70px;
  margin-bottom: 0;
}

/* end watchlist */

/* pending order */
.orders-tab.nav-tabs {
  border-bottom: 0;
  background-color: #fff;
  width: 100%;
  max-width: 127px;
  border-radius: 10px;
  margin-left: auto;
}

.orders-tab.nav-tabs .nav-link {
  padding: 8px 14px;
  border: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  text-align: center;
  width: 63px;
}

.orders-tab.nav-tabs .nav-link.active {
  background-color: #2F67F0;
  border-radius: 10px;
  border: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #fff;
  padding: 10px 14px;
  text-align: center;
}

button#dropdown-basic-button {
  background-color: #EBF0FE;
  color: #2F67F0;
  border: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-8 {
  margin-bottom: 8px;
}

.label,
.label-yellow,
.label-grey,
.label-red,
.label-green,
.label-blue,
.label-white {
  display: inline-block;
  padding: 3px 8px;
  border-radius: 3px;
  font-weight: 500;
  text-transform: uppercase;
}


.label-border-blue {
  color: var(--blue);
  border: 1px solid var(--blue);
}

.label-fill-blue {
  color: #fff;
  background-color: var(--blue);
}


.label-blue {
  color: var(--blue);
  background-color: rgba(47, 103, 240, 0.1);
}

.label-green {
  color: var(--green);
  background-color: rgba(8, 153, 129, 0.1);
}

.label-red {
  color: var(--red);
  background-color: rgba(242, 54, 69, 0.1);
}

.label-grey {
  color: var(--black-grey);
  background-color: rgba(0, 0, 0, 0.1);
}

.label-yellow {
  color: var(--orange);
  background-color: var(--yellow);
}

.label-white {
  color: var(--black);
  background-color: #fff;
}

.search-items .form-control.search-form-control.grey-bg {
  background-color: #F9FAFA;
}

.search-items .form-control.search-form-control {
  background-color: #fff;
  border-radius: 5px;
  border: 0;
  padding: 0.688rem 1rem;
  padding-left: 2.2rem;
  font-size: 12px;
  line-height: 18px;
}

.search-items .form-control.search-form-control::placeholder {
  color: #00000080;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

.search-items .form-control.search-form-control:focus {
  box-shadow: unset;
}

.search-items .search-icon {
  position: relative;
}

.search-items .search-icon .right-icon {
  position: absolute;
  top: 13px;
  left: 13px;

}

/* popup */
.orders-trades-popup.offcanvas.offcanvas-bottom.show {
  width: 100%;
  max-width: 375px;
  height: auto;
  /* left: -16px; */
  left: 0;
  border-radius: 20px;
  bottom: 0;
  margin: 0 auto;
}

.popup-lists {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.popup-lists li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
}

.popup-lists li:last-child {
  padding-bottom: 0;
}

.orders-trades-popup.offcanvas .offcanvas-header {
  display: flex;
  align-items: center;
  padding-bottom: 0;
}

.orders-trades-popup.offcanvas .offcanvas-header .btn-close {
  margin: 0 auto;
  background-image: unset;
  border-bottom: 2px solid #D9D9D9;
  border-radius: 0;
  padding: 0;
  width: 60px;
}

.orders-trades-popup.offcanvas .offcanvas-header .btn-close:focus {
  box-shadow: unset;
}

button.orders-popup-btn.btn.btn-primary {
  border-radius: 0;
  width: 100%;
  background-color: #2F67F0;
  font-weight: 400;
  line-height: 24px;
  font-size: 15px;
}

.bg-active {
  background-color: #ebf0fe;
}

.trading-balance-card {
  border-radius: 20px 20px 0 0;
}

.py-11 {
  padding: 11px 0;
}

.border-left-1 {
  border-left: 1px solid #00000080;
}

.border-bottom-1 {
  border-bottom: 1px solid #0000001A;
}

.mb-10 {
  margin-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.mb-13 {
  margin-bottom: 13px;
}

.pb-13 {
  padding-bottom: 13px;
}

.font-ml {
  font-size: var(--font-size-ml);
  font-weight: var(--font-weight-ml);
}

/* start account page  */

.ei-logo span {
  border-radius: 50%;
  padding: 20px 23px;
  background-color: var(--pink);
  color: #4A0000;
}

.account-title {
  padding: 32px 110px;
  border-radius: 5px;
}

.ei-logo {
  margin-bottom: -15px;
}

.account-lists {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.account-lists li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 18px 13px;
  margin-bottom: 10px;
  background-color: #fff;
}

.radius-5 {
  border-radius: 5px;
}

.radius-top-20 {
  border-radius: 20px 20px 0 0;
}

.profile-edit img {
  position: absolute;
  right: 0;
  top: 5px;
}

.profile-banner {
  background: linear-gradient(180deg, #2F67F0 0%, #01468E 100%);
  padding: 18px 23px;
  border-radius: 5px;
}

.font-20 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
}

.yellow-light {
  color: #FFB800;
}

.subscriptions-lists {
  list-style-type: none;
  padding-left: 0;
  border-bottom: 1px solid #0000001A;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.subscriptions-lists:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.subscriptions-lists li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.subscriptions-lists li:last-child {
  margin-bottom: 0;
}

.transaction-buttons {
  display: flex;
}

.transaction-buttons button.btn-primary {
  border-radius: 8px;
  border: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
  width: 100%;
  min-width: 160px;
  padding: 14px 33px;
}

.transaction-buttons button.deposit-btn.btn.btn-primary {
  background-color: #2F67F0;
  border: 1px solid #2F67F0;
}

.transaction-buttons button.withdraw-btn.btn.btn-primary {
  background-color: #F23645;
  border: 1px solid #F23645;
}

/* end account page  */
/* paymet method section */
.paymet-method-type {
  gap: 70px;
  margin: 25px 0;
}

.withdraw-model-box {
  padding: 30px 15px;
  border-radius: 5px;
}

.form-check-input:focus {
  box-shadow: none !important;
}

.theme-popup.modal.show .modal-content {
  max-width: 280px;
}

.theme-popup.modal.show .modal-dialog {
  display: flex;
  justify-content: center;
}

button.cancle-btns.btn-primary {
  border: 0;
  color: #F23645;
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 0;
}

button.cancle-btns.btn-primary:hover {
  color: #F23645;
  background-color: transparent;
}

button.ok-btns.btn-primary {
  border: 0;
  color: #474747;
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 0;
}

button.ok-btns.btn-primary:hover {
  color: #474747;
  background-color: transparent;
}

/* portfolio modal */
.trading-balance-modal {
  border-radius: 5px;
  margin-bottom: 16px;
}

.portfolio-modal-button {
  display: flex;
  margin-top: 16px;
  gap: 23px;
}

.portfolio-modal-button button.btn-primary {
  border-radius: 8px;
  border: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
  width: 100%;
  min-width: 160px;
  padding: 7px 33px;
}

.portfolio-modal-button button.btn-primary span {
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
}

.portfolio-modal-button button.buy-btn.btn.btn-primary {
  background-color: #089981;
  border: 1px solid #089981;
}

.portfolio-modal-button button.sell-btn.btn.btn-primary {
  background-color: #F23645;
  border: 1px solid #F23645;
}

.chart-button {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.chart-button button.btn-primary {
  border-radius: 8px;
  border: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #2F67F0;
  padding: 12px 20px;
}

.chart-button button.chart-btn.btn.btn-primary {
  background-color: #fff;
}

.border-radius-top-20 {
  border-radius: 20px 20px 0 0;
}

.border-radius-top-5 {
  border-radius: 5px 5px 0 0;
}

/* start watchlistbuysell offcanvas */
.watchlistbuysell-popup.offcanvas.offcanvas-bottom.show {
  width: 100%;
  max-width: 375px;
  height: auto;
  /* left: -16px; */
  left: 0;
  border-radius: 20px;
  margin: 0 auto;
}

.watchlistbuysell-popup.offcanvas.offcanvas-bottom.show .offcanvas-body {
  /* min-height: 750px; */
  height: calc(100vh - 120px);
  border-radius: 20px;
}



/* .woocommerce .woocommerce-MyAccount-navigation ul::-webkit-scrollbar{
  height: 0.3rem;
}
.woocommerce .woocommerce-MyAccount-navigation ul::-webkit-scrollbar-track{
  background: #ededed;
}
.woocommerce .woocommerce-MyAccount-navigation ul::-webkit-scrollbar-thumb{
  background: #568186;
} */
/* .h-831{
  min-height: 831px;
} */
/* .watchlistbuysell-popup.offcanvas .offcanvas-body{
  min-height: 713px;
} */
.popup-lists {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.popup-lists li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
}

.popup-lists li:last-child {
  padding-bottom: 0;
}

.watchlistbuysell-popup.offcanvas .offcanvas-header {
  display: flex;
  align-items: center;
  padding-bottom: 0;
}

.watchlistbuysell-popup.offcanvas .offcanvas-header .btn-close {
  margin: 0 auto;
  background-image: unset;
  border-bottom: 2px solid #D9D9D9;
  border-radius: 0;
  padding: 0;
  width: 60px;
}

.orders-trades-popup.offcanvas .offcanvas-header .btn-close:focus {
  box-shadow: unset;
}

/* end watchlistbuysell offcanvas */

/* watchlistbuy */
.watchlistbuy-tab.nav-tabs {
  border-bottom: 0;
  background-color: #0899811A;
  width: 100%;
  max-width: 150px;
  border-radius: 4px;
  margin-left: auto;
}

.watchlistbuy-tab.nav-tabs.red {
  background-color: #F236451A;
}

.watchlistbuy-tab.nav-tabs.red .nav-link.active {
  background-color: #F23645;
}

.watchlistbuy-tab.nav-tabs .nav-link {
  padding: 8px 14px;
  border: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  text-align: center;
  width: 75px;
  height: 36px;
}

.watchlistbuy-tab.nav-tabs .nav-link.active {
  background-color: #089981;
  border-radius: 10px;
  border: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #fff;
  padding: 9px 8px;
  text-align: center;
}

button.watchlistbuy-btn.btn.btn-primary {
  background-color: #089981;
  border-radius: 8px;
  border: 0;
  border: 1px solid #089981;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
  padding: 14px 55px;
}

button.watchlistbuy-btn.red.btn.btn-primary {
  background-color: #F23645;
  border: 0;
}

.cart-left-side {
  display: flex;
}

.cartminus,
.cartplus {
  width: 36px;
  height: 36px;
  color: #089981;
  border-radius: 4px;
  font-size: 24px;
  line-height: 30px;
  display: inline-block;
  padding: 0;
  background: #0899811A;
  border: 0;
}

.cartminus.red,
.cartplus.red {
  background: #F236451A;
  color: #F23645;
}

.cart-left-side input[type=text] {
  background: #fff;
  border-radius: 4px;
  border: 1px solid #00000033;
  width: 80px;
  height: 36px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  outline: 0;
  color: #6e6b7b;
  margin: 0 6px;
}

.executed-dropdown .dropdown-menu.show {
  min-width: 70px;
  box-shadow: 0px 4px 10px 0px #00000026;
  border: 0;
  border-radius: 5px;
  padding: 0 9px;
  transform: translate3d(0px, 35.0909px, 0px) !important;
}

.executed-dropdown .dropdown-menu.show .dropdown-item {
  border-bottom: 1px solid #0000000D;
  padding: 12px 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: #00000080;
}

.executed-dropdown .dropdown-menu.show .dropdown-item:last-child {
  border-bottom: 0;
}

.executed-dropdown .dropdown-menu.show .dropdown-item:hover {
  background-color: inherit;
}

.executed-dropdown .dropdown-menu.show .dropdown-item:active {
  background-color: inherit;
}

button.yes-btn.btn.btn-primary {
  background-color: #F23645;
  border-radius: 6px;
  border: 0;
  border: 1px solid #F23645;
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  min-width: 106px;
  text-align: center;
  color: #fff;
  padding: 12px 28px;
}

button.cancle-btn.btn.btn-primary {
  background-color: #2F67F0;
  border-radius: 6px;
  border: 0;
  border: 1px solid #2F67F0;
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  color: #fff;
  text-align: center;
  padding: 12px 28px;
  min-width: 106px;
}


.form-control.search-form-control {
  background-color: #fff;
  border-radius: 10px;
  border: 0;
  padding: 10px 30px;
  font-size: 12px;
  line-height: 18px;
  padding-right: 50px;
  position: relative;
  width: 100%;
}

.form-control.search-form-control::placeholder {
  color: #00000080;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

/* .search-icon {
  position: relative;
}
.search-icon .right-icon {
  position: absolute;
  bottom: -25px;
  left: 10px;
  z-index: 1;
} */
.clear-label {
  position: relative;
}

.clear-label span {
  position: absolute;
  right: 13px;
  bottom: 11px;
  color: #2F67F0;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

table.refer-table {
  border-collapse: separate;
  border-spacing: 0 1em;
}

table.refer-table thead th {
  border: 0;
  background-color: #F9FAFA;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  padding: 0 10px;
}

table.refer-table thead th:nth-child(2),
table.refer-table thead th:nth-child(3) {
  text-align: center;
}

table.refer-table tbody tr td:nth-child(4) {
  text-align: center;
}

table.refer-table tbody tr td:first-child {
  border-radius: 8px 0 0 8px;
}

table.refer-table tbody tr td:last-child {
  border-radius: 0 8px 8px 0;
}

table.refer-table tbody tr td {
  border: 0;
  font-size: 14px;
  padding: 19px 10px;
}

.refer-box {
  padding: 15px 10px;
  min-width: 100px;
  border-radius: 10px;
}

.refer-box2 {
  padding: 15px 12px;
  min-width: 95px;
  border-radius: 10px;
}

.refer-box3 {
  padding: 15px 12px;
  min-width: 100px;
  border-radius: 10px;
}


table.buysell-table thead th {
  border: 0;
  background-color: #F9FAFA;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  /* padding: 12px 10px; */
}

table.buysell-table thead th:last-child {
  text-align: right;
}

table.buysell-table tbody tr td {
  border: 0;
  font-size: 16px;
  vertical-align: middle;
  line-height: 19px;
  font-weight: 400;
  color: #000;
}

table.buysell-table tbody tr td .graph,
table.buysell-table tbody tr td .graph.red {
  position: relative;
  z-index: 9;
}

table.buysell-table tbody tr td .graph {
  color: #089981;
}

table.buysell-table tbody tr td .graph:after {
  content: "";
  position: absolute;
  background-color: #08998133;
  width: 22%;
  height: 100%;
  z-index: -1;
  right: 0;
  min-height: 27px;
  transition: width 500ms ease;
}

table.buysell-table tbody tr td .graph.red {
  padding-left: 8px;
  color: #F23645;
}

table.buysell-table tbody tr td .graph.red:after {
  content: "";
  position: absolute;
  background-color: #F2364533;
  width: 28%;
  height: 100%;
  z-index: -1;
  left: 0;
  min-height: 26px;
  /* Transition width over 500 milliseconds */
}

table.buysell-table tbody tr td:nth-child(1) .graph.red:after {
  height: 136%;
}

table.buysell-table tbody tr td .graph.width-10:after {
  width: 20%;
  min-height: 27px;
}

table.buysell-table tbody tr td .graph.width-20:after {
  width: 40%;
  min-height: 27px;
}

table.buysell-table tbody tr td .graph.width-30:after {
  width: 60%;
  min-height: 27px;
}

table.buysell-table tbody tr td .graph.width-40:after {
  width: 80%;
  min-height: 27px;
}

table.buysell-table tbody tr td .graph.width-50:after {
  width: 100%;
  min-height: 27px;
}

table.buysell-table tbody tr td .graph.width-60:after {
  width: 120%;
  min-height: 27px;
}

table.buysell-table tbody tr td .graph.width-70:after {
  width: 140%;
  min-height: 27px;
}

table.buysell-table tbody tr td .graph.width-80:after {
  width: 160%;
  min-height: 27px;
}


table.buysell-table tbody tr td .graph.width-90:after {
  width: 180%;
  min-height: 27px;
}



/* 

table.buysell-table tbody tr td .graph.width-50:after {
  width: 79%;
  min-height: 27px;
}

table.buysell-table tbody tr td .graph.red.width-70:after {
  width: 130%;
  min-height: 27px;
} */

/* table.buysell-table tbody tr td .graph.red.width-80:after {
  width: 142%;
  min-height: 27px;
} */

table.buysell-table tbody tr td .graph.width-100:after {
  width: 170%;
  min-height: 27px;
}

table.buysell-table tbody tr:nth-child(1) td {
  padding-bottom: 7px;
}

table.buysell-table tbody tr:nth-child(2) td {
  padding-top: 0;
}

table.buysell-table tbody tr:nth-child(3) td {
  padding-top: 0;
}

table.buysell-table tbody tr:nth-child(4) td {
  padding-top: 0;
}

table.buysell-table tbody tr:nth-child(5) td {
  padding-top: 0;
}

table.buysell-table tbody tr td:nth-child(4) {
  text-align: right;
}

table.buysell-table tbody tr:nth-child(6) td {
  text-align: center;
}

table.buysell-table tbody tr td:nth-child(2) {
  padding-right: 0;
  padding-bottom: 7px;
  text-align: right;
}

table.buysell-table tbody tr td:nth-child(3) {
  padding-left: 0;
  margin-left: 5px;
}

/* table.buysell-table tbody tr:nth-child(1) td,
table.buysell-table tbody tr:nth-child(2) td,
table.buysell-table tbody tr:nth-child(3) td,
table.buysell-table tbody tr:nth-child(4) td,
table.buysell-table tbody tr:nth-child(5) td{text-align: right;} */

.copy-icon-img {
  position: absolute;
  top: -35px;
  right: 5px;
}

.copy-icon {
  position: relative;
}

.refer-roadmap ul {
  padding: 0;
  margin: 0;
  margin-top: 29px;
  list-style: none;
}

.refer-roadmap .number-span {
  box-shadow: 2px 2px 6px #2722461A;
  padding: 5px 10px;
  text-align: center;
  font-size: 16px;
  line-height: 18px;
}

.refer-roadmap ul li {
  margin-top: 40px;
}

.step-line-wrapper {
  position: relative;
  width: 2px;
}

.step-line {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  content: '';
  width: 3px;
  height: 0;
  top: 0;
  height: calc(100% - 40px);
  margin-top: 20px;
}

.step-line:before {
  position: absolute;
  left: 15px;
  right: 0;
  top: -17px;
  bottom: 0;
  height: 34px;
  width: 1px;
  content: '';
  display: block;
  /* background-color: #2F67F0; */
  border-radius: 0;
  border: 1px dashed #2F67F0;
}


/* leaderboard */
.rank-label {
  background-color: #EBF0FE;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 5px;
}

table.Leader-board-table tbody tr {
  vertical-align: middle;
  border-bottom: transparent;
  border-radius: 8px;
}

table.Leader-board-table {
  border-collapse: separate;
  border-spacing: 0 1em;
}

table.Leader-board-table tbody tr td {
  font-size: 14px;
}

table.Leader-board-table tbody tr .lable-bg-blue {
  background-color: #2F67F0;
  color: #fff;
}


/*.fadeOut{
  animation: fadeIn ease 3s;
}
.fadeIN {
   animation: fadeOut ease 3s;
}
@keyframes fadeIn{
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }

}
@keyframes fadeOut{
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}*/

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Define slide-out animation */
@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

/* Apply slide-in animation to elements with .slide-in class */
.slide-in {
  animation: slideIn 0.9s ease-in-out;
}

/* Apply slide-out animation to elements with .slide-out class */
.slide-out {
  animation: slideOut 0.9s ease-in-out;
}


table.portfolio-b tbody tr,
table.portfolio-b thead {
  vertical-align: middle;
  border-bottom: transparent;
  border-radius: 8px;
}

table.portfolio-b-table {
  border-collapse: separate;
  border-spacing: 0 1em;
}


/*wactch list box main start */

/* .watch-list-box-pannel.tab-content>.active {
  max-height: 470px;
  overflow-y: auto;
} */
.watch-list-box-pannel {
  overflow-y: auto;
  height: calc(100vh - 288px);
}

.account-ledger-master-pannel {
  /* max-height: 730px;
  overflow-y: auto; */
  overflow-y: auto;
  height: calc(100vh - 0px);
}

.market-pannel-status {
  /* max-height: 350px; */
  overflow-y: auto;
  height: calc(100vh - 378px);
}

/*wactch list box main end */

/* .woocommerce .woocommerce-MyAccount-navigation ul::-webkit-scrollbar{
  height: 0.3rem;
}
.woocommerce .woocommerce-MyAccount-navigation ul::-webkit-scrollbar-track{
  background: #ededed;
}
.woocommerce .woocommerce-MyAccount-navigation ul::-webkit-scrollbar-thumb{
  background: #568186;
} */